import { Link } from "react-router-dom"
import { CarouselInfoStyle, LandingComponent } from "../../style"

export function CarouselInfo({landingData}) {
    const component = LandingComponent(landingData)
    const style = CarouselInfoStyle(landingData)
    
    return (
        <div style={{...component.infoContainer, maxWidth: "500px"}}>
            <span style={style.infoSmallText}>
                {landingData.preHeaderText}
            </span>
            <h2 style={style.infoHeader} className="header-text">{landingData.headerText}</h2>
            <hr style={style.infoLine} />
            <p style={style.infoText}>{landingData.subText}</p>
            <Link className='h-bg-darkgray' style={style.infoLink} to={landingData.buttonLink}>{landingData.buttonText}</Link>
        </div>
    )
}