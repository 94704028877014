import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Mousewheel, Pagination } from 'swiper/modules';
import { CarouselStyle } from '../../style';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/mousewheel'
import { useNavigate } from 'react-router-dom';

export default function CarouselComponent({ data }) {
    const style = CarouselStyle()
    const navigate = useNavigate()

    return (
        <>
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={window.innerWidth < 1000 ? '1' : '2'}
                coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 3,
                slideShadows: true,
                }}
                mousewheel={{
                    thresholdDelta: 70
                }}
                loop={true}
                pagination={{ clickable: true }}
                modules={[EffectCoverflow, Pagination, Mousewheel]}
            >
                {data.map((item, id) => {
                    return <SwiperSlide key={id} onClick={() => navigate(item.webLink)}>
                        {({ isActive }) => (
                            <div style={{...style.slideOuter, backgroundImage: `url(${item.image})`, backgroundSize: "cover"}}>
                                { isActive && <div style={style.slideInner}>
                                    <h2 style={style.slideHeader}>{item.label}</h2>
                                    <p style={style.slideText}>{item.subText}</p>
                                </div> }
                            </div>
                        )}
                    </SwiperSlide>
                })}
                <div style={style.moreSwipe}>{`<<< SWIPE FOR MORE >>>`}</div>
            </Swiper>
            
        </>
    );
}

/* Took out the button on each of the slides. Just in case we want to put it back (goes after the <p>)
<Link className='slide-hover' style={style.slideLink} to={`./products/${prodLine}${item.url}`}>Browse</Link>
*/