import { useEffect, useState } from "react";
import ReviewsSummary from "./components/summary";
import ReviewsHeader from "./components/header";
import SortReviews from "./components/sort";
import Reviews from "./components/reviews";
import PageSelection from "./components/pageSelection";

export default function ShopperApproved() {
    const [page, setPage] = useState(1)
    const [newestToOldest, setNewestToOldest] = useState(false)
    const [showRating, setShowRating] = useState()
    const [reviews, setReviews] = useState()
    
    useEffect(() => {
        async function getReviews() {
            const info = await fetch(`https://api.shopperapproved.com/reviews/36508?token=5380d73526&limit=8&page=${page}&sort=${newestToOldest ? 'newest' : 'highest'}&rating=${showRating ? showRating : '1,2,3,4,5'}`)
                .then(response => response.json())

            let newReviews = []
            for (const [key, value] of Object.entries(info)) {
                if(key !== 'total_count') newReviews.push(value);
            }
            setReviews(newReviews)
        }
        getReviews()
    }, [page, newestToOldest, showRating, setReviews])

    return (
        <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
            <ReviewsHeader />
            <div style={{display: "flex", flexDirection: "column", gap: "32px", justifyContent: "center", width: "100%", maxWidth: "1400px", padding: "20px"}}>
                <ReviewsSummary />
                <SortReviews newestToOldest={newestToOldest} setNewestToOldest={setNewestToOldest} showRating={showRating} setShowRating={setShowRating} />
                <Reviews reviews={reviews} />
                <PageSelection page={page} setPage={setPage} showRating={showRating} />
            </div>
        </div>
    )
}