import { useContext } from 'react';
import ProductPage from '../pages/product';
import { PrecartProvider } from '../providers/precartProvider';
import { ProductsContext } from '../providers/productsProvider';
import { Navigate, useParams } from 'react-router-dom';

export default function ProductPath() {
    const { lineID, productID } = useParams()
    const products = useContext(ProductsContext)
    const lineData = products.categories.filter((d) => d.value === lineID)

    const getProduct = () => {
        if(lineData && lineData.length > 0) {
            const prodData = lineData[0].products.filter((p) => p.value === productID)
            if(prodData && prodData.length > 0) return <PrecartProvider><ProductPage /></PrecartProvider>
        }
        return <Navigate to={'/products/' + lineID + '/'} />        
    }
    
    return getProduct()
}