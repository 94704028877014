import { useContext } from "react"
import { FontContext } from "../../providers/fontProvider"
import { ProductsContext } from "../../providers/productsProvider";

export function LandingComponent(landingData) { 
    const fonts = useContext(FontContext)
    
    return {
        container: {
            width: "100vw",
            maxWidth: "100vw",
            padding: "40px 5vw",
            display: "flex",
            flexDirection: fonts.mobile ? "column" : landingData.reverse ? "row-reverse" : "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "48px",
            scrollMarginTop: fonts.mobile ? "82px" : "92px",
            backgroundColor: landingData.backgroundColor
        },
        innerContainer: {
            width: '100vw',
            maxWidth: '1700px',
            padding: "0 5vw",
            display: "flex",
            flexDirection: fonts.mobile ? "column" : landingData.textPosition === "right" ? "row-reverse" : "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "48px",
        },
        infoContainer: {
            width: fonts.mobile ? "100%" : landingData.image && landingData.image !== '' ? "50%" : "82%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        },
        contentContainer: {
            flex: "1 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative"
        }
}}

export function HeroStyle(landingData) {
    const fonts = useContext(FontContext)

    function getTextPosition() {
        if(fonts.mobile) {
            if(landingData.mobileTextPosition === "left") return 'flex-start'
            if(landingData.mobileTextPosition === "center") return 'center'
            if(landingData.mobileTextPosition === "right") return 'flex-end'
        }
        if(landingData.textPosition === "left") return 'flex-start'
        if(landingData.textPosition === "center") return 'center'
        if(landingData.textPosition === "right") return 'flex-end'
    }
    
    return {
        container: {
            width: "100vw",
            height: "65vh",
            backgroundImage: `url(${fonts.mobile ? landingData.mobileImage : landingData.image})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            position: "relative",
            zIndex: "0"
        },
        video: {
            height: "65vh",
            position: "absolute",
            top: "0",
            left: "0",
            zIndex: "5"
        },
        fade: {
            width: "100vw",
            height: "65vh",
            background: fonts.mobile ? landingData.mobileFadeValue : landingData.fadeValue,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: "0",
            left: "0",
            zIndex: "10"
        },
        inner: {
            width: "100%",
            maxWidth: "1600px",
            padding: fonts.mobile ? "0" : "0 8vw",
            display: "flex",
            justifyContent: getTextPosition(),
            alignItems: "center"
        },
        textContainer: {
            width: "100%",
            maxWidth: landingData.maxTextWidth || "100%",
            marginTop: landingData.goUnderHeader ? fonts.mobile ? "41px" : "46px" : "0",
            display: "flex",
            flexDirection: "column",
            gap: fonts.mobile ? "20px" : "40px",
            color: landingData.textColor,
            textAlign: fonts.mobile ? landingData.mobileTextPosition : landingData.textPosition,
            alignItems: getTextPosition()
        },
        mobileLogo: {
            marginBottom: "30px"
        },
        header: {
            fontSize: landingData.logoImage ? fonts.hero.fontSize : fonts.mobile ? '11vw' : 'min(95px, 6vw)',
            fontWeight: '700',
            lineHeight: landingData.logoImage ? fonts.hero.lineHeight : fonts.mobile ? '12vw' : 'min(115px, 7vw)',
            width: "100%",
            textTransform: "uppercase"
        },
        subText: {
            fontSize: "60px", 
            fontWeight: "400", 
            lineHeight: "60px",
            width: "100%",
        },
        button: {
            // color and background-color are set in component
            width: "fit-content",
            borderWidth: fonts.mobile ? "3px" : "5px",
            borderStyle: "solid",
            borderColor: landingData.buttonBorderColor,
            fontSize: window.innerWidth < 1200 ? "32px" : "min(40px, 3.5vw)",
            fontWeight: "700",
            padding: window.innerWidth < 1200 ? "15px 30px" : "min(24px, 1.5vw) min(30px, 1.5vw)",
            display: "flex",
            justifyContent: "center",
            textDecoration: "none",
            alignItems: "center",
            borderRadius: "2vw",
            textTransform: "uppercase",
            cursor: "pointer",
            transitionDuration: ".3s"
        }
    }
}

export function FeatureStyle(landingData) {
    const fonts = useContext(FontContext)
    
    return {
        wordsContainer: {
            width: "100%",
            padding: fonts.mobile ? "60px 2vw 20px" : "30px 30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "24px",
            color: landingData.textColor,
            textAlign: landingData.textAlign,
        },
        textHeader: {
            fontSize: "50px",
            fontWeight: "800",
            width: "100%",
        },
        textSub: {
            ...fonts.large,
            width: "100%"
        },
        textDescription: {
            ...fonts.standard,
            width: "100%",
            paddingBottom: "24px"
        },
        buttonContainer: {
            padding: "12px 0",
            alignSelf: fonts.mobile ? "center" : "flex-start",
        },
        button: {
            backgroundColor: landingData.textColor,
            fontSize: "12px",
            fontWeight: "500",
            color: landingData.backgroundColor,
            textDecoration: "none",
            textTransform: "uppercase",
            padding: "8px 25px",
            lineHeight: "18px",
            borderRadius: "30px",
            cursor: "pointer",
            transitionDuration: ".2s",
            display: "flex",
            flex: "0 0 auto",
            alignItems: "center",
            borderWidth: "2px",
            borderStyle: "solid",
            
        },
        arrow: {
            marginTop: "-3px", 
            marginLeft: "10px", 
            fontWeight: "700",
            transitionDuration: ".2s"
        }
    }
}

export function ProductDisplayStyle() {
    const fonts = useContext(FontContext)

    return {
        allImageContainer: {
            width: "100%",
            maxWidth: "1700px",
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: fonts.mobile ? "20px" : "40px"
        },
        imageContainer: {
            width: "min(20vw, 395px)",
            minWidth: "180px",
            height: "min(20vw, 395px)",
            minHeight: "180px",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        image: {
            width: "100%",
            height: "100%",
            transitionDuration: ".3s"
        },
        hoveredImage: {
            width: "106%",
            height: "106%"
        }
    }
}

export function ShopperApprovedStyle(landingData) {
    const fonts = useContext(FontContext)
    
    return {
        container: {
            width: '100vw',
            padding: "24px 0",
            backgroundColor: landingData.backgroundColor,
            display: "flex",
            justifyContent: "center"
        },
        inner: {
            width: "100%",
            maxWidth: "1400px"
        },
        textContainer: {
            width: fonts.mobile ? "calc(100% - 40px)" : "100%",
            display: "flex",
            gap: "16px",
            alignItems: "center",
            justifyContent: fonts.mobile ? "center" : "flex-start",
            textTransform: "uppercase",
            fontSize: fonts.mobile ? "28px" : "60px",
            padding: fonts.mobile ? "20px 0" : "40px",
            borderBottom: fonts.mobile ? `2px solid ${landingData.textColor}` : "0",
            margin: fonts.mobile ? "0 20px" : "0"
        },
        thin: {
            fontWeight: "400",
            color: landingData.textColor,
        },
        bold: {
            fontWeight: "600",
            color: landingData.textColor,
        },
        line: {
            height: fonts.mobile ? "3px" : "5px",
            backgroundColor: landingData.textColor
        },
        reviewOuter: {
            display: "flex",
            gap: "16px",
            padding: "10px 50px",
            alignItems :"center",
            marginBottom: fonts.mobile ? "20px" : "40px"
        },
        leftArrow: {
            borderStyle: "solid",
            borderColor: "currentColor",
            borderWidth: "0 4px 4px 0",
            display: "inline-block",
            padding: "4px",
            transform: "rotate(135deg)",
            cursor: "pointer"
        },
        rightArrow: {
            borderStyle: "solid",
            borderColor: "currentColor",
            borderWidth: "0 4px 4px 0",
            display: "inline-block",
            padding: "4px",
            transform: "rotate(-45deg)",
            cursor: "pointer"
        },
        reviewInner: {
            backgroundColor: landingData.reviewBackgroundColor,
            color: landingData.reviewTextColor,
            display: "flex",
            gap: "8px",
            flexDirection: "column",
            border: "1px solid #dddddd",
            borderRadius: "10px",
            padding: "25px 20px",
            height: "220px",
            overflow: "hidden"
        },
        reviewContainer: {
            flex: "1 0",
            position: "relative",
            overflow: "hidden",
            height: "220px"
        },
        review: {
            position: "absolute",
            top: "0",
            width: "190px",
            maxWidth: "190px",
            backgroundColor: landingData.reviewBackgroundColor,
            color: landingData.reviewTextColor,
            display: "flex",
            gap: "8px",
            flexDirection: "column",
            border: "1px solid #dddddd",
            borderRadius: "10px",
            padding: "25px 20px",
            height: "220px",
            maxHeight: "220px",
            overflow: "hidden",
            transitionDuration: ".5s"
        }
    }
}

export function CarouselStyle() {
    const fonts = useContext(FontContext)
    
    return {
        slideOuter: {
            width: "100%",
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end"
        },    
        slideInner: {
            padding: "48px 32px 60px",
            background: "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.7) 50%, rgba(0,0,0,.8) 100%)"
        },    
        slideHeader: {
            color: "#ffffff",
            fontWeight: "600",
            fontSize: "24px",
            lineHeight: "28px",
            marginBottom: "12px",
            textTransform: "uppercase"
        },      
        slideText: {
            color: "#dadada",
            fontWeight: "500",
            lineHeight: "18px",
            fontSize: "14px"
        },      
        slideLink: {
            fontSize: "12px",
            border: "2px solid #c2c2c2",
            marginTop: "24px",
            padding: "10px 20px",
            display: "inline-block",
            textDecoration: "none",
            textTransform: "uppercase",
            color: "#717171",
            fontWeight: "500",
            background: "#ffffff",
            borderRadius: "24px",
            transitionDuration: "0.4s"
        },
        moreSwipe: {
            width: "100%", 
            textAlign: "center", 
            color: "#000000", 
            fontSize: "12px", 
            fontWeight: '500', 
            position: 'relative', 
            bottom: '0px',
            display: fonts.mobile ? 'block' : 'none'
        }
    }
}

export function CarouselInfoStyle(landingData) {
    const fonts = useContext(FontContext)
    
    return {
        infoSmallText: {
            textTransform: "uppercase",
            letterSpacing: "1.5px",
            fontSize: "16px",
            color: landingData.headerTextColor
        },
        infoHeader: {
            textTransform: "capitalize",
            letterSpacing: "0.8px",
            fontWeight: "800",
            fontSize: fonts.mobile ? "50px" : window.innerWidth > 1175 ? "55px" : "40px",
            backgroundColor: landingData.headerTextColor,
            backgroundImage: `linear-gradient(45deg, ${landingData.headerTextColor}, #000000)`
        },
        infoLine: {
            display: "block",
            background: "#005baa",
            height: "4px",
            width: "100px",
            border: "none",
            margin: "18px 0 30px 0",
            backgroundColor: landingData.headerTextColor
        },
        infoText: {
            fontSize: "16px",
            lineHeight: "24px",
            color: landingData.textColor
        },
        infoLink: {
            display: "block",
            width: "fit-content",
            textDecoration: "none",
            textTransform: "uppercase",
            fontWeight: "500",
            borderRadius: "50px",
            transitionDuration: "0.4s",
            marginTop: "34px",
            padding: "10px 30px",
            backgroundColor: landingData.buttonBackgroundColor,
            color: landingData.buttonTextColor
        }
    }
}

export function ReviewsStyle(landingData) {
    const theme = useContext(ProductsContext).theme
    const fonts = useContext(FontContext)

    return {
        container: {
            width: "100%",
            padding: fonts.mobile ? "60px 4vw" : "100px 40px",
            scrollMarginTop: fonts.mobile ? "82px" : "92px",
            backgroundColor: landingData.useStyleB ? theme.componentBackground : theme.pageBackground, 
            color: landingData.useStyleB ? theme.componentText : theme.pageText
        },
        header: {
            ...fonts.h1,
            fontWeight: "300",
            width: "100%",
            textAlign: "center",
            paddingBottom: "40px",
            color: landingData.useStyleB ? theme.componentHeader : theme.pageHeader
        },
        inner: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "50px 5vw",
            justifyContent: "center",
            alignItems: "center"
        },
        reviewContainer: {
            width: "100%",
            maxWidth: "900px",
            display: "flex",
            flexDirection: fonts.mobile ? "column" : "row",
            gap: fonts.mobile ? "20px" : "30px",
            alignItems: "center"
        },
        textContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            textAlign: fonts.mobile ? "center" : "left"
        },
        text: {
            ...fonts.standard
        },
        name: {
            ...fonts.h4
        }
    }
}

export function AboutUsStyle(landingData) {
    const theme = useContext(ProductsContext).theme
    const fonts = useContext(FontContext)

    return {
        container: {
            width: "100%",
            padding: fonts.mobile ? "60px 4vw" : "100px 40px",
            scrollMarginTop: fonts.mobile ? "82px" : "92px",
            backgroundColor: landingData.useStyleB ? theme.componentBackground : theme.pageBackground, 
            color: landingData.useStyleB ? theme.componentText : theme.pageText
        },
        header: {
            ...fonts.h1,
            fontWeight: "300",
            width: "100%",
            textAlign: "center",
            paddingBottom: "40px",
            color: landingData.useStyleB ? theme.componentHeader : theme.pageHeader
        },
        inner: {
            display: "flex",
            flexDirection: fonts.mobile ? "column" : "row",
            gap: "80px",
            justifyContent: "center"
        },
        infoContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            width: "100%",
            maxWidth: "500px"
        },
        littleHeader: {
            ...fonts.large,
            textAlign: fonts.mobile ? "center" : "left"
        },
        info: {
            ...fonts.standard,
            lineHeight: "24px",
            textAlign: fonts.mobile ? "center" : "left"
        },
        imageContainer: {
            display: "flex",
            alignItems: "center",
            width: "100%",
            maxWidth: "350px"
        }
    }
}