import { ReactComponent as FacebookIcon } from '../../icons/facebook.svg'
import { ReactComponent as InstagramIcon } from '../../icons/instagram.svg'
import { ReactComponent as YouTubeIcon } from '../../icons/youtube.svg'
import { Link } from 'react-router-dom'
import { FooterStyle } from '../style'
import { useContext } from 'react'
import { ProductsContext } from '../../providers/productsProvider'

export function Footer({landingFooter = false}) {
    const products = useContext(ProductsContext)
    const style = FooterStyle()

    let featuredArray = []
    let productArray = []
    products.categories.forEach((cat) => {
        for(var i = 0; i < cat.products.length; i++) {
            if(featuredArray.length < 5) featuredArray.push({category: cat.value, name: cat.products[i].label, id: cat.products[i].value})
            else {
                if(productArray.length < 5) productArray.push({category: cat.value, name: cat.products[i].label, id: cat.products[i].value})
            }
        }
    })
        
    
    return(
        <div style={{...style.container, marginTop: landingFooter ? '0' : '80px' }}>
            <div style={style.inner}>
                <div style={{...style.section, alignItems: "left", paddingRight: "30px"}}>
                    <img style={style.logo} alt="small logo" title="small logo" loading="lazy" src={products.config.footerLogo} width="150px" height="auto" />
                    <div style={{textAlign: "center"}}>
                        <div style={style.link}>615 W. Knox Rd</div>
                        <div style={style.link}>Tempe, AZ 85284</div>
                    </div>
                    <div style={style.iconContainer}>
                        <Link to='https://www.facebook.com/SeatDecorUSA' target="_blank" rel="noopener" style={style.iconHolder} className='footer-hover' aria-label='Link to Facebook'>
                            <FacebookIcon style={style.icon} />
                        </Link>
                        <Link to='https://www.instagram.com/decorauto/' target="_blank" rel="noopener" style={style.iconHolder} className='footer-hover' aria-label='Link to Instagram'>
                            <InstagramIcon style={style.icon} />
                        </Link>
                        <Link to='https://www.youtube.com/@SeatDecor' target="_blank" rel="noopener" style={style.iconHolder} className='footer-hover' aria-label='Link to YouTube'>
                            <YouTubeIcon style={style.icon} />
                        </Link>
                    </div>
                </div>
                <div style={{...style.section, alignItems: "left"}}>
                    <div style={style.header}>Featured Products</div>
                    { featuredArray.map((prod, id) => {
                        return <Link to={'/products/' + prod.category + '/' + prod.value} key={id} className='footer-hover' style={style.link}>{prod.name}</Link>
                    })}
                </div>
                <div style={{...style.section, alignItems: "left"}}>
                    <div style={style.header}>Other Products</div>
                    { productArray.map((prod, id) => {
                        return <Link to={'/products/' + prod.category + '/' + prod.value} key={id} className='footer-hover' style={style.link}>{prod.name}</Link>
                    })}
                </div>
                <div style={{...style.section, alignItems: "left"}}>
                    <div style={style.header}>Company Links</div>
                    <Link to='/contact/' className='footer-hover' style={style.link}>Contact Us</Link>
                    <Link to='/about/' className='footer-hover' style={style.link}>About Us</Link>
                    <Link to='/faq/' className='footer-hover' style={style.link}>Frequently Asked Questions</Link>
                    <Link to='https://dashdesignsdealers.com/index.php' target="_blank" className='footer-hover' style={style.link}>Dealer Login</Link>
                    <Link to='https://www.dashdesigns.com/' target="_blank" className='footer-hover' style={style.link}>Dash Designs Wesite</Link>
                </div>
            </div>
            <div style={style.line} />
            <div style={style.copyright}>{products.config.copywriteLine}</div>
        </div>
    )
}

/* I was told to take this out of the footer, but leaving down here just in case

<div style={style.section}>
    <div style={style.header}>Company</div>
    <Link to='https://www.dashdesigns.com' target="_blank" style={style.link}>Dash Designs Website</Link>
    <Link to='https://dashdesignsdealers.com/index.php' target="_blank" style={style.link}>Dealer Login</Link>
    <Link to='https://www.dashdesigns.com/locator.html' target="_blank" style={style.link}>Dealer Locator</Link>
</div>
<div style={style.section}>
    <div style={style.header}>Useful Links</div>
    <Link to={'/' + landing.landingID + '/contact/'} style={style.link}>Contact Us</Link>
    <Link to={'/' + landing.landingID + '/faq/'} style={style.link}>Frequently Asked Questions</Link>
    <Link to={'/' + landing.landingID + '/privacy/'} style={style.link}>Privacy Policy</Link>
</div>
<div style={style.section}>
    <div style={style.header}>Follow Us</div>
    <Link to='https://www.facebook.com/SeatDecorUSA' target="_blank" style={style.link}>Facebook</Link>
    <Link to='https://www.instagram.com/decorauto/' target="_blank" style={style.link}>Instagram</Link>
    <Link to='https://www.youtube.com/@SeatDecor' target="_blank" style={style.link}>YouTube</Link>
</div>
*/