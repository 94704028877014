import { useContext } from "react"
import { FontContext } from "../../providers/fontProvider"
import { ProductsContext } from "../../providers/productsProvider";

export function TitleStyle() { 
    const theme = useContext(ProductsContext).theme
    const fonts = useContext(FontContext)

    return {
        container: {
            ...fonts.h2,
            fontWeight: "400",
            width: "100%",
            color: theme.pageHeader,
            textTransform: "uppercase",
            textAlign: "center",
            padding: "0 0 24px"
        }
    }
}

export function SwatchStyle() { 
    const products = useContext(ProductsContext)
    const theme = products.theme
    const fonts = useContext(FontContext)
    
    return {
        swatchContainer: {
            display: "flex",
            flexWrap: "wrap",
            gap: fonts.mobile ? "1vw" : "48px",
            width: "100%",
            maxWidth: "1700px",
            justifyContent: "center"
        },
        container: {
            display: "flex",
            flexDirection: "column",
            padding: fonts.mobile ? "20px 8px" : "24px 16px",
            backgroundColor: theme.componentBackground,
            width: fonts.mobile ? "48vw" : "96vw",
            maxWidth: "320px",
            gap: fonts.mobile ? "12px" : "18px"
        },
        imageContainer: {
            overflow: "hidden",
            width: "100%",
            cursor: "zoom-in"
        },
        optionContainer: {
            display: "flex",
            gap: "4px",
            justifyContent: "center",
            flexWrap: "wrap",
            cursor: "pointer"
        },
        optionBorder: {
            border: "2px solid transparent",
            borderRadius: "12px",
            textDecoration: "none",
            transitionDuration: ".2s"
        },
        option: {
            width: fonts.mobile ? "32px" : "40px",
            height: fonts.mobile ? "32px" : "40px",
            borderRadius: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: `2px solid ${theme.componentBackground}`,
            boxShadow: '0 0 2px #dddddd'
        },
        number: {
            ...fonts.small,
            color: "#ffffff"
        },
        name: {
            fontSize: fonts.mobile ? "14px" : "16px", 
            fontWeight: "600", 
            lineHeight: "20px",
            textDecoration: "none",
            color: theme.componentHeader
        },
        tagline: {
            fontSize: fonts.mobile ? "12px" : "14px",
            fontWeight: "400",
            lineHeight: "20px",
            textDecoration: "none",
            color: theme.componentText
        },
        priceContainer: {
            display: "flex",
            alignItems: "center",
            gap: fonts.mobile ? "2px" : "8px",
            textDecoration: "none"
        },
        price: {
            fontSize: fonts.mobile ? "14px" : "20px",
            color: products.config.wholeSiteDiscountRate && products.config.wholeSiteDiscountRate > 0 ? theme.specialBackground : theme.componentHeader,
            fontWeight: "600",
            paddingRight: fonts.mobile ? "12px" : "16px"
        },
        priceOld: {
            fontSize: "12px",
            width: "100%",
            paddingLeft: "8px",
            textDecoration: "line-through",
            color: theme.componentText,
            fontWeight: "500"
        },
        reviews: {
            fontSize: fonts.mobile ? "12px" : "14px", 
            fontWeight: "400", 
            lineHeight: "20px",
            color: theme.componentHeader
        },
        button: {
            fontSize: fonts.mobile ? "12px" : "14px", 
            fontWeight: "600", 
            lineHeight: "18px",
            color: theme.pageHeader,
            border: `2px solid ${theme.pageHeader}`,
            width: "100%",
            padding: fonts.mobile ? "8px 16px" : "11px 22px",
            textTransform: "uppercase",
            textDecoration: "none",
            backgroundColor: theme.pageBackground,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "3px",
            transitionDuration: ".3s"
        }
    }
}