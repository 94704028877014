import { ReviewsStyle } from "../../style"

export function Reviews({ landingData }) {
    const style = ReviewsStyle(landingData)
    
    return(
        <div id={landingData.headerLinkID} style={style.container}>
            <div style={style.header}>{landingData.headerText}</div>
            <div style={style.inner} id='sa-body-342'>
                { landingData.reviews.map((review, id) => {
                    return <div key={id} style={style.reviewContainer}>
                        <img src={review.image} style={style.image} alt={review.name} title={review.name + ' Icon'} loading="lazy" height="150px" width="150px" />
                        <div style={style.textContainer}>
                            <div style={style.text}>{review.text}</div>
                            <div style={style.name}>{review.name}&nbsp;&nbsp;&nbsp;<i>{review.location}</i></div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}