import Select from "react-select"
import { ReviewsStyle } from "../../../style"

export default function SortReviews({ newestToOldest, setNewestToOldest, showRating, setShowRating }) {
    const style = ReviewsStyle()

    const sortBy = [
        { value: "highest", label: "Highest to Lowest" },
        { value: "date", label: "Newest to Oldest" }
    ]

    const ratings = [
        { value: "all", label: "All Ratings" },
        { value: 5, label: "Five Star" },
        { value: 4, label: "Four Star" },
        { value: 3, label: "Three Star" },
        { value: 2, label: "Two Star" },
        { value: 1, label: "One Star" },
    ]

    const handleSort = (selection) => {
        if(selection.value === "date" && !newestToOldest) setNewestToOldest(true)
        else if(selection.value === 'highest' && newestToOldest) setNewestToOldest(false)
    }

    const handleRating = (selection) => {
        if(selection.value === "all") setShowRating()
        else setShowRating(selection.value)
    }
    
    return (
        <div style={style.sortContainer} id="product-review-start">
            <div style={{display: "flex", gap: "20px", alignItems: "center"}}>
                <div style={{fontSize: "16px", fontWeight: "600"}}>Sort Reviews By</div>
                <Select 
                    styles={{
                        container: (base) => ({...base, maxWidth: "250px"}),
                        control: (base) => ({...base, ...style.item })
                    }}
                    value={newestToOldest ? sortBy[1] : sortBy[0]}
                    options={sortBy}
                    onChange={handleSort}
                    isSearchable={false}
                    aria-label="Sort Ratings by"
                />
            </div>
            <div style={{display: "flex", gap: "20px", alignItems: "center"}}>
                <div style={{fontSize: "16px", fontWeight: "600"}}>Show Only</div>
                <Select 
                    styles={{
                        container: (base) => ({...base, maxWidth: "250px"}),
                        control: (base) => ({...base, ...style.item })
                    }}
                    value={showRating ? ratings[5 - showRating + 1] : ratings[0]}
                    options={ratings}
                    onChange={handleRating}
                    isSearchable={false}
                    aria-label="Show Only"
                />
            </div>
        </div>
    )
}