import { useEffect, useState } from "react"
import { ShopperApprovedStyle } from "../../style"
import { StarRating } from "../../../../components/stars"
import { Link } from "react-router-dom"
import { ReactComponent as ShopperApprovedLogo } from '../../../../icons/shopperApproved.svg'
import Review from "./review"

export default function ShopperApproved({ landingData }) {
    const [reviews, setReviews] = useState([])
    const [index, setIndex] = useState(0)
    const style = ShopperApprovedStyle(landingData)
    
    useEffect(() => {
        if(reviews.length === 0) {
            async function getReviews() {
                const info = await fetch('https://api.shopperapproved.com/reviews/36508?token=5380d73526&rating=4,5&sort=highest&full_name=0&limit=30')
                    .then(response => response.json())
                let newReviews = []
                for (const [key, value] of Object.entries(info)) {
                    if(key !== 'total_count') newReviews.push(value);
                }
                setReviews(newReviews)
            }
            getReviews()
        }

        const timeout = setTimeout(() => {
            if(index < 20) setIndex(index + 1)
        }, 4000)
        return () => clearTimeout(timeout)
    }, [setReviews, index, setIndex, reviews])

    return (
        <div style={style.container}>
            <div style={style.inner}>
                <div style={style.textContainer} id="customer-reviews">
                    <div style={style.thin}>Verified</div>
                    <div style={style.bold}>Reviews</div>
                    { window.innerWidth > 500 && <div style={{...style.line, flex:" 1 0"}} /> }
                </div>
                <div style={style.reviewOuter}>
                    <div style={{padding: "14px", color: landingData.textColor, cursor: "pointer"}} className="hover" onClick={() => index > 0 && setIndex(index - 1)}>
                        <div style={style.leftArrow} />
                    </div>
                    <div style={{...style.reviewInner, justifyContent: "center", alignItems: "center"}}>
                        <div style={{paddingBottom: "12px"}}>
                            <span style={{fontSize: "38px", fontWeight: "600"}}>4.8&nbsp;</span>
                            <span style={{fontSize: "20px", fontWeight: "600"}}>/ 5</span>
                        </div>
                        <StarRating rating={4.8} specify={'init'} isSmall={false} color={landingData.reviewStarColor} backgroundColor={landingData.reviewTextColor} />
                        <Link to="https://www.shopperapproved.com/reviews/dashdesigns.com/" target="_blank" style={{color: landingData.reviewTextColor, fontWeight: "600"}}>
                            10,815 Verified Reviews
                        </Link>
                        <ShopperApprovedLogo style={{width:"180px", height:"60px"}} />
                    </div>
                    <div style={style.reviewContainer}>
                        { reviews.map((review, id) => {
                            return Math.abs(id - index) <= 7 && <Review key={id} id={id} index={index} landingData={landingData} review={review} />
                        })}
                    </div>
                    <div style={{padding: "14px", color: landingData.textColor, cursor: "pointer"}} className="hover" onClick={() => index < 20 && setIndex(index + 1)}>
                        <div style={style.rightArrow} />
                    </div>
                </div>
            </div>
        </div>
    )
}