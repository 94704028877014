import { ReviewsStyle } from "../../../style"

export default function ReviewsHeader() {
    const style = ReviewsStyle()
    
    return (
        <div style={style.textContainer}>
            <div style={style.thin}>Customer</div>
            <div style={style.bold}>Reviews</div>
            { window.innerWidth > 500 && <div style={{...style.line, flex:" 1 0"}} /> }
        </div>
    )
}