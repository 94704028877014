import { FAQTOC } from "./toc"
import { Questions } from "./questions"
import { FaqStyle } from "../style"
import { useEffect, useState } from "react"

export function FAQ({ faqs }) {
    const style = FaqStyle()
    const [newFaqs, setNewFaqs] = useState()

    useEffect(() => {
        let newFaq = []
        faqs.questions.forEach((question) => {
            if(!question.enabled) return
            const headerLabel = faqs.headers.filter(h => h.value === question.header)[0]
            const subHeaderLabel = faqs.subHeaders.filter(h => h.value === question.subHeader)[0]
            let parent = newFaq.filter(n => n.title === headerLabel.label)[0]
            if(!parent) {
                newFaq.push({title: headerLabel.label, categories: []})
                parent = newFaq[newFaq.length - 1]
            }
            let child = parent.categories.filter(c => c.category === subHeaderLabel.label)[0]
            if(!child) {
                parent.categories.push({category: subHeaderLabel.label, questions: []})
                child = parent.categories[parent.categories.length - 1]
            }
            child.questions.push({ question: question.question, answer: question.answer})
        })
        setNewFaqs(newFaq)
    }, [faqs, setNewFaqs])
    
    return(
        newFaqs && <div style={style.container}>
            <FAQTOC faqs={newFaqs} />
            <Questions faqs={newFaqs} />
        </div>
    )
}