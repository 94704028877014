import { Header } from "../../components/header"
import { ContactUsContainer } from "./components/container"
import { Footer } from "../../components/footer"
import { TOC } from "../../components/toc"
import { Helmet } from "react-helmet-async"
import { useContext } from "react"
import { ProductsContext } from "../../providers/productsProvider"

export default function ContactUsPage() {
    const products = useContext(ProductsContext)

    return (
        <>
            <Helmet>
                <title>Contact Us - {products.config.label}</title>
                <meta name="description" content={`Contact ${products.config.label} for any questions you may have. Or call us at ${products.config.contactPhone}.`} />
                <link rel="canonical" href={products.websiteURL + '/contact'} />
            </Helmet>
            <Header />
            <TOC contact />
            <ContactUsContainer config={products.config} />
            <Footer />
        </>
    )
}