import { Header } from "../../components/header"
import { FAQContainer } from "./components/container"
import { Footer } from "../../components/footer"
import { TOC } from "../../components/toc"
import { Helmet } from "react-helmet-async"
import { useContext } from "react"
import { ProductsContext } from "../../providers/productsProvider"

export default function FAQPage() {
    const products = useContext(ProductsContext)

    return (
        <>
            <Helmet>
                <title>Frequently Asked Questions - {products.config.label}</title>
                <meta name="description" content={`Frequently Asked Questions for ${products.config.label}`} />
                <link rel="canonical" href={products.config.websiteURL + '/faq'} />
            </Helmet>
            <Header />
            <TOC faq />
            <FAQContainer products={products} />
            <Footer />
        </>
    )
}