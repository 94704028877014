import { ReactComponent as AboutIcon } from '../../../icons/about.svg'
import { Link } from "react-router-dom";
import { HeaderStyle } from "../../style";

export default function About({ landingData }) {
    const style = HeaderStyle(landingData)
    
    return (
        <Link to={'/about/'} style={style.iconContainer} className="hover" aria-label='About Us'>
            <AboutIcon style={style.icon} />
            <div style={style.iconText}>ABOUT US</div>
        </Link>
    );
}