import { Link } from "react-router-dom"
import { ProductDisplayStyle } from "../../style"
import { useState } from "react"

export default function ProductDisplayImage({ data }) {
    const [hovered, setHovered] = useState(false)
    const style = ProductDisplayStyle()
    
    return (
        <Link to={data.webLink} style={style.imageContainer} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            <img src={data.image} style={{ transitionDuration: ".1s", width: hovered ? "106%" : "100%", height: hovered ? "106%" : "100%"}} alt={`Featured Product`} />
        </Link>
    )
}