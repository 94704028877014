import FeatureInfo from './info';
import FeaturePicture from './picture';
import { LandingComponent } from '../../style';

export default function Feature({ landingData }) {
    const style = LandingComponent(landingData)

    return (
        <div style={style.container} >
            <div style={style.innerContainer}>
                <FeatureInfo landingData={landingData} />
                { landingData.image && landingData.image !== '' && <FeaturePicture landingData={landingData} /> }
            </div>
        </div>
    )
}