import { useEffect, useState } from "react";
import { ReviewsStyle } from "../../../style";
import { StarRating } from "../../../../../components/stars";
import { ReactComponent as StarIcon } from "../../../../../icons/star.svg"

export default function ReviewsSummary() {
    const style = ReviewsStyle()
    const [stats, setStats] = useState()

    useEffect(() => {
        async function getSummary() {
            const info = await fetch('https://api.shopperapproved.com/aggregates/reviews/36508?token=5380d73526')
                .then(response => response.json())

            setStats(info)
        }
        getSummary()
    }, [setStats])

    return (
        stats && <div style={style.summaryContainer}>
            <div style={{display: "flex", flexDirection: "column", gap: "4px", alignItems: "center"}}>
                <div style={{fontSize: "38px", fontWeight: "700"}}>{stats.average_rating}</div>
                <StarRating rating={stats.average_rating} />
                <div style={{fontSize: "16px", fontWeight: "600"}}>{stats.total_reviews} Reviews</div>
            </div>
            <div style={{flex: "1 0", display: "flex", flexDirection: "column"}}>
                <div style={{display: "flex", width: "100%", gap: "10px", alignItems: "center"}}>
                    <div style={{fontSize: "18px", width: "13px", minWidth: "13px"}}>5</div>
                    <div style={{width: "14px", minWidth: "14px", height: "18px"}}><StarIcon/></div>
                    <div style={{...style.summaryLine, width: Math.round((stats['5_star'] / stats.total_reviews) * 100) + '%'}} />
                    <div>{stats['5_star']}</div>
                    <div>({Math.round((stats['5_star'] / stats.total_reviews) * 100)}%)</div>
                </div>
                <div style={{display: "flex", width: "100%", gap: "10px", alignItems: "center"}}>
                    <div style={{fontSize: "18px", width: "13px"}}>4</div>
                    <div style={{width: "14px", minWidth: "14px", height: "18px"}}><StarIcon/></div>
                    <div style={{...style.summaryLine, width: Math.round((stats['4_star'] / stats.total_reviews) * 100) + '%'}} />
                    <div>{stats['4_star']}</div>
                    <div>( {Math.round((stats['4_star'] / stats.total_reviews) * 100)}% )</div>
                </div>
                <div style={{display: "flex", width: "100%", gap: "10px", alignItems: "center"}}>
                    <div style={{fontSize: "18px", width: "13px"}}>3</div>
                    <div style={{width: "14px", minWidth: "14px", height: "18px"}}><StarIcon/></div>
                    <div style={{...style.summaryLine, width: Math.round((stats['3_star'] / stats.total_reviews) * 100) + '%'}} />
                    <div>{stats['3_star']}</div>
                    <div>( {Math.round((stats['3_star'] / stats.total_reviews) * 100)}% )</div>
                </div>
                <div style={{display: "flex", width: "100%", gap: "10px", alignItems: "center"}}>
                    <div style={{fontSize: "18px", width: "13px"}}>2</div>
                    <div style={{width: "14px", minWidth: "14px", height: "18px"}}><StarIcon/></div>
                    <div style={{...style.summaryLine, width: Math.round((stats['2_star'] / stats.total_reviews) * 100) + '%'}} />
                    <div>{stats['2_star']}</div>
                    <div>( {Math.round((stats['2_star'] / stats.total_reviews) * 100)}% )</div>
                </div>
                <div style={{display: "flex", width: "100%", gap: "10px", alignItems: "center"}}>
                    <div style={{fontSize: "18px", width: "13px"}}>1</div>
                    <div style={{width: "14px", minWidth: "14px", height: "18px"}}><StarIcon/></div>
                    <div style={{...style.summaryLine, width: Math.round((stats['1_star'] / stats.total_reviews) * 100) + '%'}} />
                    <div>{stats['1_star']}</div>
                    <div>( {Math.round((stats['1_star'] / stats.total_reviews) * 100)}% )</div>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "column", gap: "4px", alignItems: "center"}}>
                <div style={style.summaryPercent}>{Math.round(((stats['5_star'] + stats['4_star']) / stats.total_reviews) * 100)} %</div>
                <div style={{fontSize: "16px", fontWeight: "400"}}>of customers rate this</div>
                <div style={{fontSize: "16px", fontWeight: "400"}}>company 4- or 5-stars</div>
            </div>
        </div>
    )
}