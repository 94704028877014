import { LandingComponent, ProductDisplayStyle } from "../../style"
import ProductDisplayImage from "./image"

export default function ProductDisplay({ landingData }) {
    const component = LandingComponent(landingData)
    const style = ProductDisplayStyle()
    
    return (
        landingData.products && landingData.products.length > 0 &&
        <div style={{...component.container, backgroundColor: landingData.backgroundColor}}>
            <div style={component.innerContainer}>
                <div style={style.allImageContainer}>
                    { landingData.products.map((image, id) => {
                        return <ProductDisplayImage data={image} key={id} />
                    })}
                </div>
            </div>
        </div>
    )
}