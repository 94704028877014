import { useQuery } from "@tanstack/react-query";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CartContext } from "./cartProvider";
import { ProductsContext } from "./productsProvider";

export const PrecartContext = createContext({});

export function PrecartProvider({ children }) {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [vehicle, setVehicleState] = useState({})
    const [imageSelection, setImageSelection] = useState(0)
    const [colorOption, setColorOptionState] = useState(0)
    const [fileproOptionsLoading, setFileproOptionsLoading] = useState(false)
    const [currentFileproOption, setCurrentFileproOptionState] = useState([])
    const [quantity, setQuantity] = useState(1)
    const [showPrecheck, setShowPrecheckState] = useState(false)
    const [showFullImage, setShowFullImage] = useState(false)
    
    const { lineID, productID } = useParams()
    const products = useContext(ProductsContext)
    const lineData = products.categories.filter((line) => line.value === lineID)[0]
    const productData = lineData.products.filter((product) => product.value === productID)[0]
    const cart = useContext(CartContext)

    useEffect(() => {
        const savedData = JSON.parse(localStorage.getItem("vehicleData"))
        if(savedData) {
            setVehicleState({
                year: savedData.year,
                make: savedData.make,
                model: savedData.model
            })
        }
    }, [setVehicleState])
    
    useEffect(() => {
        if(searchParams.has("a")) setColorOptionState(parseInt(searchParams.get("a")))
    }, [searchParams])
    
    const getFileproOptions = async() => {
        setFileproOptionsLoading(true)
        const apiAddress = process.env.REACT_APP_API_SERVER + '/web/vehicleoptions/'
        const result = await fetch(apiAddress, {
            method: "POST",
            headers: { "Content-Type": "application/json", "authentication": process.env.REACT_APP_DD_API + Date.now().toString().slice(5, 10) },
            body: JSON.stringify({
                prodLine: productData.prodLine,
                year: vehicle.year,
                make: vehicle.make,
                model: vehicle.model
            })
        })
        .then((response) => response.json())

        setFileproOptionsLoading(false)
        return result
    }

    const fileproOptions = useQuery({
        queryKey: ["fileproOptions", vehicle.model, productData.value],
        queryFn: getFileproOptions,
        enabled: !!vehicle.model
    })

    const setVehicle = useCallback((vehicleObject) => {
        setCurrentFileproOptionState([])
        setVehicleState(vehicleObject)
        if(vehicleObject.year && vehicleObject.make && vehicleObject.model)
            localStorage.setItem("vehicleData", JSON.stringify(vehicleObject))
    }, [])

    const setColorOption = useCallback((number) => {
        setColorOptionState(number)
    }, [])

    const setCurrentFileproOption = useCallback((fpOption) => {
        let selected = [...currentFileproOption]
        if(selected.includes(fpOption)) {
            var index = selected.indexOf(fpOption)
            if(index !== -1) selected.splice(index, 1)
        }
        else {
            if((fpOption === '1ST ROW BENCH' && selected.includes('1ST ROW BUCKETS')) || 
            (fpOption === '1ST ROW BUCKETS' && selected.includes('1ST ROW BENCH'))) {
                if(selected.includes('2ND ROW BENCH')) selected = ['2ND ROW BENCH']
                else selected = []
            }
            if((fpOption === 'FULL SET (2 ROWS)' && selected.includes('FRONT SET')) || 
            (fpOption === 'FRONT SET' && selected.includes('FULL SET (2 ROWS)'))) {
                if(selected.includes('CARGO')) selected = ['CARGO']
                else selected = []
            }
            selected.push(fpOption)
        }
        selected.sort()
        setCurrentFileproOptionState(selected)
    }, [currentFileproOption])

    const setShowPrecheck = useCallback(() => {
        const app = document.getElementById('app-container')
        if(showPrecheck) {
            app.style.overflow = 'auto'
            app.style.height = null
            setShowPrecheckState(false)
        }
        else {
            app.style.overflow = 'hidden'
            app.style.height = '100vh'
            setShowPrecheckState(true)
        }
    }, [showPrecheck])

    const setEditValues = useCallback((id) => {
        if(cart.items && cart.items.length > id) {
            const item = cart.items[id]
            setVehicle({
                year: item.year,
                make: item.make,
                model: item.model
            })
            setCurrentFileproOptionState([item.row])
            setQuantity(item.quantity)
        }
        else navigate('/shopping-cart')
    }, [cart, navigate, setVehicle])

    const addToCart = useCallback((options = null, gotConsole = false, editID = null) => {
        let cartItems = []
        let purchase = {}

        purchase.category = lineData.value

        if(!productData.disableVehicleSelection) {
            purchase.year = vehicle.year
            purchase.make = vehicle.make
            purchase.model = vehicle.model
        }

        if(productData.options.length > 0 && (colorOption || colorOption === 0)) {
            purchase.fabric = productData.options[colorOption].fabricID
            purchase.colorID = colorOption
            purchase.color = productData.options[colorOption].colorID
            purchase.colorName = productData.options[colorOption].name
            purchase.image = productData.options[colorOption].images[0]
            purchase.smallImage = productData.options[colorOption].preview
        }
        else purchase.image = productData.images[0]

        let deliveryDate = new Date()
        deliveryDate.setDate(deliveryDate.getDate() + lineData.shippingDays + 4)

        purchase.price = parseFloat(productData.cost * (1 - (products.config.wholeSiteDiscountRate / 100)))
        purchase.product = productData.value
        purchase.productName = productData.label
        purchase.productType = productData.genericType
        purchase.line = productData.prodLine
        purchase.delivery = deliveryDate.toISOString()
        purchase.quantity = quantity

        if(options) {
            for(var i = 0; i < options.length; i++) {
                let optPurchase = {...purchase}
                optPurchase.item = options[i].Item
                if(productData.prodLine === '430') {
                    optPurchase.armrest = options[i].Armrest
                    optPurchase.console = options[i].Console
                    optPurchase.headrest = options[i].Headrest
                    optPurchase.row = options[i].Type
                    optPurchase.image = '/img/styles/K' + optPurchase.item.substring(1, 4) + '.jpg'
                }
                if(productData.prodLine === '300') {
                    if(options[i].Type === 'FULL SET (2 ROWS)') optPurchase.price += products.config.floormatFullSet2UpgradeCost
                    else if(options[i].Type === 'FULL SET (3 ROWS)') optPurchase.price += products.config.floormatFullSet3UpgradeCost
                    else if(options[i].Type.toLowerCase().includes('cargo')) optPurchase.price += products.config.floormatCargoUpgradeCost
                }
                cartItems.push(optPurchase)
                if(i === 0 && gotConsole) {
                    let consolePurchase = {...purchase}
                    consolePurchase.item = options[0].Console
                    consolePurchase.price = products.config.upgradedConsoleCost
                    consolePurchase.upgradedConsole = true
                    consolePurchase.image = '/img/styles/console-upgrade.jpg'
                    consolePurchase.productName = ''
                    consolePurchase.productType = 'Console Cover Upgrade'
                    cartItems.push(consolePurchase)
                }
            }
        }
        else cartItems.push(purchase)

        cart.addItem(cartItems, editID)
    }, [cart, colorOption, lineData, productData, vehicle, quantity, products.config])

    // console.log(fileproOptions.data?.payload)

    const memoizedValue = useMemo(
        () => ({
            vehicle: vehicle,
            setVehicle: setVehicle,
            imageSelection: imageSelection,
            setImageSelection: setImageSelection,
            fileproOptions: fileproOptions.data?.payload || null,
            fileproOptionsLoading: fileproOptionsLoading,
            currentFileproOption: currentFileproOption,
            setCurrentFileproOption: setCurrentFileproOption,
            colorOption: colorOption,
            setColorOption: setColorOption,
            quantity: quantity,
            setQuantity: setQuantity,
            showPrecheck: showPrecheck,
            setShowPrecheck: setShowPrecheck,
            showFullImage: showFullImage,
            setShowFullImage: setShowFullImage,
            addToCart: addToCart,
            setEditValues: setEditValues
        }), [
            vehicle, setVehicle, imageSelection, setImageSelection, fileproOptions, fileproOptionsLoading, 
            currentFileproOption, setCurrentFileproOption, colorOption, setColorOption, quantity, setQuantity, 
            showPrecheck, setShowPrecheck, showFullImage, setShowFullImage, addToCart, setEditValues
        ]
    );

    return <PrecartContext.Provider value={memoizedValue}>{children}</PrecartContext.Provider>;
}