import React, { createContext, useState, useEffect } from 'react';
import NetworkError from '../components/networkError';
import { LoadingPage } from '../components/loading';
import { useSearchParams } from 'react-router-dom';

export const ProductsContext = createContext({});

export function ProductsProvider({ children }) {    
    const [data, setData] = useState()
    const [searchParams] = useSearchParams()
    const sid = searchParams.get("s")

    useEffect(() => {
        if(!data) {
            const baseURL = process.env.REACT_APP_API_SERVER
            const site = window.location.origin
            const siteSelection = sid ? sid : site
            
            const options = {
                headers: {
                    "Content-Type": "application/json", 
                    "authentication": process.env.REACT_APP_DD_API + Date.now().toString().slice(5, 10),
                    "type": sid ? "sandbox" : "legit",
                    "site": siteSelection
                }
            }

            fetch(baseURL + '/web/data/site/', options)
                .then(response => response.json())
                .then(info => { console.log(info); setData(info)})
                .catch(err => console.log(err))
        }
    }, [data, setData, sid])
    
    if(!data) return <LoadingPage />

    return <ProductsContext.Provider value={data.payload}>
        { data.status === 200 ? children : <NetworkError /> }
    </ProductsContext.Provider>;
}