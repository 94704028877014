import { ReviewsStyle } from "../../../style"
import { ReactComponent as VerifiedIcon } from '../../../../../icons/verifiedReview.svg'
import { StarRating } from "../../../../../components/stars"

export default function Reviews({ reviews }) {
    const style = ReviewsStyle()

    function getDate(reviewDate) {
        const date = new Date(reviewDate)
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
    }
    
    return (
        reviews && <div>
            { reviews.map((review, id) => {
                return <div style={style.reviewContainer} key={id}>
                    <div style={{display: "flex", flexDirection: "column", gap: "12px"}}>
                        <div style={{fontSize: "16px", fontWeight: "600"}}>{review.display_name}</div>
                        <StarRating rating={review.overall} specify={"product-review-" + id}/>
                        <div style={{fontSize: "14px", fontWeight: "400"}}>{getDate(review.review_date)}</div>
                        <div style={{fontSize: "16px", fontWeight: "400"}}>{review.comments || '[No Comment]'}</div>
                    </div>
                    { window.innerWidth >= 500 && <div style={{width: "140px"}}>
                        <VerifiedIcon />
                    </div> }
                </div>
            })}
        </div>
    )
}