import React, { useState, useContext } from 'react';
import Shipping from './shipping';
import { Payment } from './payment';
import { ContainerStyle } from '../style';
import { CartContext } from '../../../providers/cartProvider';
import Contact from './contact';
import { useNavigate } from 'react-router-dom';
import ErrorPopup from '../components/errorPopup';

export default function Inputs({ setSendingOrder }) {
    const style = ContainerStyle()
    const cart = useContext(CartContext)
    const navigate = useNavigate()
    const apiAddress = process.env.REACT_APP_API_SERVER + '/web/order/retail/payment/'
    const [shippingValues, setShippingValues] = useState()
    const [contact, setContact] = useState()

    const [page, setPage] = useState(0)
    const [message, setMessage] = useState("")

    const movePastContact = (fields) => {
        setContact(fields)
        cart.setContactValues(fields)
        setPage(1)
    }

    const movePastShipping = (fields) => {
        setShippingValues(fields)
        cart.setShippingValues(fields)
        setPage(2)
    }

    const submitOrder = async (fields) => {
        setSendingOrder(true)
        let transaction = fields
        if(fields.cardNumber && fields.cardNumber !== '') {
            let payment = {...fields}
            payment.amount = Math.round((cart.subTotal - cart.discount + cart.tax) * 100) / 100
            const request = await fetch(apiAddress, {
                method: "POST",
                headers: { "Content-Type": "application/json", "authentication": process.env.REACT_APP_DD_API + Date.now().toString().slice(5, 10) },
                body: JSON.stringify({
                    contact: {email: contact.email},
                    shipping: {...shippingValues, name: contact.firstName + ' ' + contact.lastName, phone: contact.phone},
                    payment: payment
                })
            }).then(response => response.json())
            
            if(request.status === 200) transaction = request.payload
            else {
                setMessage(request.payload.toString())
                return
            }
        }
        
        const orderSent = await cart.submitOrder(transaction)
        setSendingOrder(false)

        if(orderSent.status === 200) navigate('/completed')
        else setMessage('Cannot submit order at this time. Please try again.')
    }
    
    return (
        <div style={style.inputsContainer}>
            <Contact page={page} setPage={setPage} moveOn={movePastContact} />
            <Shipping page={page} setPage={setPage} moveOn={movePastShipping} />
            <Payment page={page} submitOrder={submitOrder} />
            {message && message !== "" && <ErrorPopup message={message} setMessage={setMessage} /> } 
        </div>  
    );
}