import { ReactComponent as ContactUsIcon } from '../../../icons/contact-us.svg'
import { Link } from "react-router-dom";
import { HeaderStyle } from "../../style";

export default function ContactUs({ landingData }) {
    const style = HeaderStyle(landingData)
    
    return (
        <Link to={'/contact/'} style={style.iconContainer} aria-label='Contact Us' className="hover">
            <ContactUsIcon style={style.icon} fill='none' />
            <div style={style.iconText}>CONTACT</div>
        </Link>
    );
}