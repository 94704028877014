import React, { useContext, useState } from "react";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import ErrorPopup from "./errorPopup";
import { CartContext } from "../../../providers/cartProvider";

export default function Paypal({client, submitOrder}) {
    const apiAddress = process.env.REACT_APP_API_SERVER + '/web/order/retail/paypal/'
    const cart = useContext(CartContext)
    const [message, setMessage] = useState("")
    const style = {shape: "rect", layout: "vertical"}
    let controlNumbers

    const initialOptions = {
        "client-id": client,
        "components": "buttons",
        intent: "capture",
        currency: "USD",
        "disable-funding": "paylater,venmo,card"
    };

    const createOrder = async() => {
        const itemList = cart.getItemList()
        const tax = itemList.reduce((taxSum, it) => { return taxSum + it.tax.value * it.quantity }, 0)
        
        const result = await fetch(apiAddress, {
            method: "POST",
            headers: { "Content-Type": "application/json", "authentication": process.env.REACT_APP_DD_API + Date.now().toString().slice(5, 10) },
            body: JSON.stringify({
                cart: itemList,
                subtotal: Math.round(cart.subTotal * 100) / 100,
                tax: Math.round(tax * 100) / 100,
                discount: Math.round(cart.discount * 100) / 100
            })
        }).then(response => response.json())
        controlNumbers = result.controlNumbers
        
        if(result.status === "CREATED") return result.id
        else setMessage('PayPal payments are temporarily unavailable.')
    }

    const onApprove = async(data, actions) => {
        const result = await fetch(apiAddress + data.orderID, {
            method: "POST",
            headers: { "Content-Type": "application/json", "authentication": process.env.REACT_APP_DD_API + Date.now().toString().slice(5, 10) },
        }).then(response => response.json())
            
        if(result.status === 'COMPLETED') {
            const transaction = {
                paymentMethod: 'paypal',
                transId: result.purchase_units[0].payments.captures[0].id,
                authCode: result.id,
                amount: parseFloat(result.purchase_units[0].payments.captures[0].amount.value),
                control: controlNumbers.control,
                so: controlNumbers.so
            }
            submitOrder(transaction)
        }
        else setMessage(data?.details?.[0] || 'PayPal Payment Error')
    }

    const showError = (data) => {
        console.log(data.toString())
    }

    return (
        <div style={{width: "100%", marginTop: "20px"}}>
            <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons style={style}
                    forceReRender={[style]}
                    createOrder={createOrder}
                    onError={showError}
                    onApprove={onApprove}
                />
            </PayPalScriptProvider>
            { message && message !== "" && <ErrorPopup message={message} setMessage={setMessage} />}
        </div>
    );
}