import { useContext } from "react";
import { ReactComponent as ShoppingCartIcon } from '../../../icons/shopping-cart.svg'
import { CartContext } from "../../../providers/cartProvider";
import { useNavigate } from "react-router-dom";
import { HeaderStyle } from "../../style";

export default function ShoppingCart({ setHover, landingData }) {
    const style = HeaderStyle(landingData)
    const cart = useContext(CartContext)
    const navigate = useNavigate()

    const enterCheck = () => {
        if(window.location.href.toString().includes('shopping-cart')) return
        if(window.location.href.toString().includes('checkout')) return
        if(window.location.href.toString().includes('completed')) return
        if(cart.showSpecifications) return
        setHover(true)
    }
    
    return (
        <div style={{...style.iconContainer, ...style.cart, zIndex: cart.showSpecifications ? '0' : '120'}} className="hover" 
            onMouseEnter={enterCheck} 
            onClick={() => window.location.href.toString().includes('shopping-cart') ? null : navigate('/shopping-cart/')}
        >
            <ShoppingCartIcon style={style.icon} fill='none' />
            <div style={style.iconText}>CART</div>
            { cart.items && cart.items.length > 0 ? <div style={style.cartNumber}>
                <div style={style.cartNumberText}>{cart.items.length}</div>
            </div> : null }
        </div>
    );
}