import { Link } from 'react-router-dom';
import { HeroStyle } from '../../style';
import { useState } from 'react';

export default function HeroVideo({ landingData }) {
    const [buttonHover, setButtonHover] = useState(false)
    const style = HeroStyle(landingData)

    const buttonStyle =  {
        ...style.button,
        backgroundColor: buttonHover && landingData.buttonBackgroundColor !== 'transparent' ? 
            `color-mix(in oklab, ${landingData.buttonBackgroundColor} 90%, #000000)` : 
            landingData.buttonBackgroundColor,
        borderColor: buttonHover ?
            `color-mix(in oklab, ${landingData.buttonBorderColor} 90%, #000000)` : 
            landingData.buttonBorderColor,
        color: buttonHover && landingData.buttonBackgroundColor === 'transparent' ?
            `color-mix(in oklab, ${landingData.buttonTextColor} 90%, #000000)` : 
            landingData.buttonTextColor
    }

    return (
        <div style={style.container}>
            <video autoPlay muted loop style={style.video}>
                <source src={landingData.videoLink} type="video/mp4" />
            </video>
            <div style={style.fade}>
                <div style={style.inner}>
                    <div style={style.textContainer}>
                        { landingData.logoImage && landingData.logoImage !== '' &&
                            <img style={style.mobileLogo} src={landingData.logoImage} alt={'Hero Logo'} title={'Hero Logo'} loading="eager" width="100%" height="auto" />
                        }
                        { landingData.headerText && landingData.headerText !== '' &&
                            <h1 style={style.header}>{landingData.headerText}</h1>
                        }
                        { landingData.subText && landingData.subText !== '' && 
                            <h3 style={style.subText}>{landingData.subText}</h3> 
                        }
                        <Link to={'.' + landingData.buttonLink} style={buttonStyle} onMouseEnter={() => setButtonHover(true)} onMouseLeave={() => setButtonHover(false)}>
                            {landingData.buttonText}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}