import { TocStyle } from "../style"

export function FAQTOC({ faqs }) {
    const style = TocStyle()

    function scrollTo(element) {
        document.querySelector(`#${element}`).scrollIntoView({ 
            behavior: 'smooth' 
        });
    }

    return(
        <>
            <h1 style={style.tocTitle}>FREQUENTLY ASKED QUESTIONS</h1>
            <div style={style.container}>
                { faqs.map((title, id) => {
                    return <div key={id} style={style.section}>
                        <div style={style.title}>{title.title}</div>
                        { title.categories.map((category, key) => {
                            return <div key={key} className="hover" onClick={() => scrollTo('scroll-' + id + key)} style={style.link}>{category.category}</div>
                        })}
                    </div>
                })}
            </div>
        </>
    )
}