import { Header } from "../../components/header"
import { Footer } from "../../components/footer"
import { Helmet } from "react-helmet-async"
import { Bag } from "./components/bag"
import { CartSummary } from "./components/summary"
import { CartPageStyle } from "./style"
import { useContext } from "react"
import { ProductsContext } from "../../providers/productsProvider"
import { CartContext } from "../../providers/cartProvider"

export default function CartPage() {
    const products = useContext(ProductsContext)
    const cart = useContext(CartContext)
    const style = CartPageStyle()
    
    return (
        <>
            <Helmet>
                <title>Shopping Cart</title>
                <meta name="description" content={products.config.label + ` Shopping Cart Page. Order today by visiting us at ` + products.config.websiteURL + '!'} />
                <link rel="canonical" href={products.config.websiteURL + '/shopping-cart'} />
            </Helmet>
            <Header />
            <div style={style.container}>
                <Bag products={products} cart={cart} />
                <CartSummary cart={cart} />
            </div>
            <Footer />
        </>
    )
}