import { useContext, useEffect, useState } from "react"
import { ReviewsStyle } from "../../../style"
import { ProductsContext } from "../../../../../providers/productsProvider"

export default function PageSelection({page, setPage, showRating}) {
    const style = ReviewsStyle()
    const theme = useContext(ProductsContext).theme
    const [numbers, setNumbers] = useState()

    useEffect(() => {
        if(showRating === 1 || showRating === 2) setNumbers([1])
        else if(showRating === 3) setNumbers([1, 2])
        else {
            let startNum = 1
            if(showRating && page > 3) startNum = 3
            else if(page >= 3) startNum = page - 2
            let numberArray = []
            for(var i = startNum; i < startNum + 5; i++) numberArray.push(i)
            setNumbers(numberArray)
        }
    }, [page, showRating, setNumbers])

    const setNewPage = (id) => {
        setPage(id)
        scrollTo('product-review-start')
    }

    function scrollTo(element) {
        document.querySelector(`#${element}`).scrollIntoView({ 
            behavior: 'smooth' 
        });
    }

    const mouseEnter = (event) => {
        event.target.style['background-color'] = `color-mix(in oklab, ${theme.pageBackground}, #9a9a9a 90%)`
    }

    const mouseLeave = (event) => {
        event.target.style['background-color'] = ''
    }
    
    return (
        numbers && <div style={{display: "flex", gap: "24px", alignItems: "center"}}>
            <div style={{fontSize: "16px", fontWeight: "600", color: theme.pageText}}>Page</div>
            { numbers.map((num) => {
                return <div key={num}
                    style={page === num ? style.numberSelected : style.number} 
                    onClick={() => setNewPage(num)}
                    onMouseEnter={num !== page ? mouseEnter : null}
                    onMouseLeave={num !== page ? mouseLeave : null}
                >{num}</div>
            })}
        </div>
    )
}