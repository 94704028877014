import { Header } from "../../components/header"
import { Footer } from "../../components/footer"
import { Helmet } from "react-helmet-async"
import { Completed } from "./components/completed"
import { useContext } from "react"
import { ProductsContext } from "../../providers/productsProvider"


export default function CompletedOrder() {
    const products = useContext(ProductsContext)
    
    return(
        <>
            <Helmet>
                <title>Order Complete - {products.config.label}</title>
                <meta name="description" content={products.config.label + ` Order Placed. Thank you for shopping at ` + products.config.websiteURL + '.'} />
                <link rel="canonical" href={products.config.websiteURL + '/completed'} />
            </Helmet>
            <Header />
            <Completed />
            <Footer />
        </>
    )
}