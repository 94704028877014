import { StarRating } from "../../../../components/stars";
import { ShopperApprovedStyle } from "../../style";

export default function Review({ landingData, id, index, review }) {
    const style = ShopperApprovedStyle(landingData)
    
    function getDate(reviewDate) {
        const date = new Date(reviewDate)
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
    }
    
    return (
        <div style={{...style.review, left: (id - index) * 200}}>
            <StarRating rating={review.overall} specify={'ind'} isSmall color={landingData.reviewStarColor} backgroundColor={landingData.reviewTextColor} />
            <div style={{fontSize: "16px", fontWeight: "600"}}>{review.display_name}</div>
            <div style={{fontSize: "13px", fontWeight: "400"}}>{getDate(review.last_modified)} - {review.location.split(',')[0]}</div>
            <div style={{fontSize: "16px", fontWeight: "400"}}>{review.comments}</div>
        </div> 
    )
}