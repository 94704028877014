import { useContext } from "react"
import { Link, useNavigate } from "react-router-dom"
import { FontContext } from "../../../providers/fontProvider"
import { CompletedItemsStyle, CompletedStyle } from "../style"
import { CartContext } from "../../../providers/cartProvider"

export function Completed() {
    const style = CompletedStyle()
    const itemsStyle = CompletedItemsStyle()
    const navigate = useNavigate()
    const fonts = useContext(FontContext)
    const cart = useContext(CartContext)
    const details = cart.completedOrder

    if(!details) navigate('/shopping-cart', { replace: true })

    const deliveryDate = () => {
        const date = new Date(details.shipping.deliveryDate)
        return date.toLocaleDateString('en-us', { year:"numeric", month:"long", day:"numeric"})
    }

    const deliveryMethod = () => {
        if(details.shipping.shippingMethod === '12') return 'USPS Priority Mail'
        else if(details.shipping.shippingMethod === '20') return 'FedEx Ground'
        else return 'FedEx Ground Home'
    }
    
    return (
        details && <div style={style.container}>
            <div style={style.pieces}>
                <div style={{...style.title, textAlign: "center"}}>Checkout Confirmation - Order #{details.order.so}</div>
                <div style={style.invoiceContainer}>
                    <div style={style.invoiceText}>
                        Thank you for your order! Your invoice will be emailed to you within an hour.
                    </div>
                </div>
            </div>
            <div style={style.pieces}>
                <div style={style.title}>Shipping Information</div>
                <div style={style.miniContainer}>
                    <div style={style.infoContainer}>
                        <div style={style.header}>Shipping Address</div>
                        <div>{details.shipping.name}</div>
                        { details.shipping.company && <div>{details.shipping.company}</div> }
                        <div>{details.shipping.address} {details.shipping.address2}</div>
                        <div>{details.shipping.city}, {details.shipping.state} {details.shipping.zip}</div>
                        <div>United States</div>
                        <div>{details.shipping.phone}</div>
                    </div>
                    <div style={style.infoContainer}>
                        <div style={style.header}>Estimated Arrival Date</div>
                        <div>{ deliveryDate() }</div>
                        <div style={{...style.header, paddingTop: "12px"}}>Shipping Method</div>
                        <div>{deliveryMethod()}</div>
                    </div>
                </div>
            </div>
            <div style={style.pieces}>
                <div style={style.title}>Order Details</div>
                { details.order.items.map((prod, id) => {
                    return <div key={id} style={{...itemsStyle.itemContainer, borderBottom: id === details.order.items.length - 1 ? "none" : itemsStyle.borderBottom}}>
                        <img style={itemsStyle.image} src={prod.image} alt={prod.productName + ' ' + prod.productType} title={prod.productName + ' ' + prod.productType} loading="eager" width={fonts.mobile ? "114px" : "164px"} height={fonts.mobile ? "114px" : "164px"} />
                        <div style={itemsStyle.detailsContainer}>
                            <div style={itemsStyle.name}>{prod.name}</div>
                            { prod.year && <div style={style.details}>{prod.year} {prod.make} {prod.model}</div> }
                            { prod.color && <div style={style.details}>Color: {prod.color}</div> }
                            { prod.row && <div style={style.details}>{prod.row === 'CONSOLE' ? 'CONSOLE UPGRADE' : 'Row: ' + prod.row}</div> }
                            <div style={style.details}>Quantity: {prod.quantity}</div>
                        </div>
                        <div style={itemsStyle.costContainer}>
                            <div style={itemsStyle.cost}>${prod.totalPrice.toFixed(2)}</div>
                        </div>
                    </div>
                })}
                <div style={style.costContainer}>
                    <div style={style.costLine}>
                        <div style={style.costDetails}>Subtotal:</div>
                        <div style={style.costDetails}>$ {details.order.subTotal.toFixed(2)}</div>
                    </div>
                    <div style={style.costLine}>
                        <div style={style.costDetails}>Taxes:</div>
                        <div style={style.costDetails}>$ {details.order.tax.toFixed(2)}</div>
                    </div>
                    <div style={style.costLine}>
                        <div style={style.costDetails}>Discounts:</div>
                        <div style={style.costDetails}>$ {details.order.discount.toFixed(2) || '0.00'}</div>
                    </div>
                    <div style={style.costLine}>
                        <div style={style.cost}>TOTAL:</div>
                        <div style={style.cost}>$ {details.order.total.toFixed(2)}</div>
                    </div>
                </div>
            </div>
            <div style={style.pieces}>
                <div style={style.title}>Questions or Concerns?</div>
                <div style={style.details}>
                    Reach out to us by visiting our&nbsp;
                    <Link style={style.link} className='remove-discount-hover' to={'/contact/'}>Contact Page</Link>
                </div>
            </div>
        </div>
    )
}