import { useContext, useEffect } from "react";
import { Header } from "../../components/header";
import { TOC } from "../../components/toc";
import { LineTitle } from "./components/title";
import { Swatch } from "./components/swatch";
import { Footer } from "../../components/footer";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { ProductsContext } from "../../providers/productsProvider";
import { SwatchStyle } from "./style";

export default function LinePage() {
    const navigate = useNavigate()
    const style = SwatchStyle()
    const {lineID} = useParams()
    const products = useContext(ProductsContext)
    const lineData = products.categories.filter((p) => p.value === lineID)[0]
    
    useEffect(() => {
        if(lineData && lineData.products.length === 1) {
            navigate('/products/' + lineID + '/' + lineData.products[0].value + '/', { replace: true })
        }
    }, [navigate, lineData, lineID])
    
    return (
        <>
            <Helmet>
                <title>{lineData.label} - {products.config.label}</title>
                <meta name="description" content={lineData.metaDescription} />
                <link rel="canonical" href={products.config.websiteURL + '/products/' + lineID} />
            </Helmet>
            <Header />
            <TOC />
            <LineTitle title={lineData.categoryHeader} />
            <div style={style.swatchContainer}>
                { lineData.products.map((product, id) => {
                    return <Swatch key={id} productDetails={product} specify={id} config={products.config} theme={products.theme} />
                })}
            </div>
            <Footer />
        </>
    );
}