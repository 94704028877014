import { AboutUsStyle } from "./../style"

export default function AboutUs({ products }) {
    const style = AboutUsStyle(products.theme)
    
    return (
        <div style={style.container}>
            <div style={style.header}>About Us</div>
            <div style={style.inner}>
                <div style={style.infoContainer}>
                    <div style={style.littleHeader}>{products.config.label}</div>
                    <div style={style.info}>Since 1983, Dash Designs® has been the leading manufacturer of Innovative Custom Fabric Auto Accessories. Today, we still proudly manufacture all of our Custom-Fit Accessories at our facility right here in Tempe, AZ. Our precision patterns are always coupled with the finest sun and heat resistant fabrics ensuring our covers are built to last. Utilizing the latest CAD-CAM manufacturing processes and our team of highly skilled sewing operators we continue providing that superior fit and finish we are known for. With over 30 years' experience we are the experts when it comes to Interior Automotive Accessories.</div>
                </div>
                <div style={style.imageContainer}>
                    <img style={style.image} src={'/img/other/dash-designs-building.webp'} alt={'Dash Designs Building'} title={'Dash Designs Building'} loading="lazy" width="100%" height="auto" />
                </div>
            </div>
        </div>
    )
}